import axios from "axios";
import { updateGlobalLoader } from "../../components/backdrop/backdropSlice";
import Alert from "../../components/dialog/Alert";
import { updateCommonData } from "../../redux/commonSlice";
import { api } from "../../utils/api";
import { encodedURI, removeEmptyValues, setCompanyFlowStatus } from "../../utils/common";
import { BASE_URL, FAILURE } from "../../utils/constant";
import { endpoints } from "../../utils/endpoints";
import { updateUser } from "./userSlice";


export const getAllCreator = () => {
  return async (dispatch, getState) => {
    const { limit, page, filters, sort_type } = getState().userSlice;
    const filterData = encodedURI(removeEmptyValues(filters));
    dispatch(updateUser({ loader: true }));
    api.getApiCall(
      endpoints.getAllInfluencers,
      `?limit=${limit}&offset=${page * limit}&filter=${filterData}&sortBy=${sort_type}`,
      (respData) => {
        let res = respData.data;
        dispatch(updateUser({ loader: false, listing: res.result.data, totalCount: res.result.count, totalPages: res.result.count }));
      },
      (errors) => {
        dispatch(updateUser({ loader: false }));
        //dispatch(updateGlobalLoader(false));
        console.log("error", errors);
      }
    );
  };
};

export const deleteCreator = (id) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.deleteApiCall(
      endpoints.deleteCreator + `/${id}`,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        dispatch(getAllCreator());
      },
      (error) => {
        dispatch(updateGlobalLoader(false));
        let { data } = error;
        Alert(2, data?.msg);
      }
    );
  };
};

export const addGroupMember = (id) => {
  return (dispatch, getState) => {
    const { selectedAllUserCheckbox, selectedRows } = getState().userSlice;
    const data = { influencerIds: [...new Set([...selectedAllUserCheckbox, ...selectedRows.map((v) => v.id)])], groupId: id }
    api.postApiCall(
      endpoints.addGroupMember,
      data,
      (respData) => {

      },
      (error) => {
        //dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );


  }
}
export const removeGroupMember = (id, ids) => {
  return (dispatch, getState) => {
    const data = { influencerIds: ids, groupId: id }
    api.postApiCall(
      endpoints.removeGroupMember,
      data,
      (respData) => {

      },
      (error) => {
        //dispatch(updateGlobalLoader(false));
        let { data } = error;
        dispatch(updateCommonData({
          message: data.message,
          title: "Failed",
          img: "/failed.png",
          status: true
        }));
      }
    );


  }
}



export const getUserSocialDetailsById = (id, setOpen) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    dispatch(updateUser({ detailLoader: true }))
    api.getApiCall(
      endpoints.getUserSocialDetails + id,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        let res = respData.data;
        if (res.status === "FAILURE") {

          dispatch(updateCommonData({
            message: res.message,
            title: "Insufficient Credits",
            img: "/sub_exp.png",
            status: true,
            getPlanButton: true
          }));
          setOpen(false)
        } else {
          setOpen(true)
          dispatch(updateUser({ details: respData.data.result, detailLoader: false }));
        }
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getUserSocialContentsById = (id, type) => {
  return (dispatch, getState) => {
    const { limit, page } = getState().userSlice;
    //dispatch(updateGlobalLoader(true));
    dispatch(updateUser({ detailLoader: true }))
    api.getApiCall(
      endpoints.getContents + `${id}?type=${type}&limit=${limit}&offset=${page * limit}`,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        dispatch(updateUser({ userContents: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};


export const getUserAllBrandsById = (id) => {
  return (dispatch, getState) => {
    const { limit, page } = getState().userSlice;
    //dispatch(updateGlobalLoader(true));
    api.getApiCall(
      endpoints.getAllBrands + `${id}?limit=${limit}&offset=${page * limit}`,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        dispatch(updateUser({ allbrands: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getBrandPosts = (brandId, handleId) => {
  return (dispatch, getState) => {
    const { brandPostlimit, brandPostpage } = getState().userSlice;
    api.getApiCall(
      endpoints.getBrandPosts + `/${handleId}/${brandId}?limit=${brandPostlimit}&offset=${brandPostpage * brandPostlimit}`,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        dispatch(updateUser({ brandsPosts: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};
export const getEmail = (id, setOpen2) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getEmail + `/${id}`,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status === "FAILURE") {
          // setOpen2(false);
          dispatch(updateCommonData({
            message: respData.data.result.message,
            title: respData.data.result?.customErrorNumber !== 400005 ? "Insufficient Credits" : "",
            img: "/sub_exp.png",
            status: true,
            getPlanButton: true
          }));
        } else {
          setOpen2(true);
          dispatch(updateUser({ emailDisplay: respData.data.result.email, emailMsgDisplay: "" }));
        }

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};
export const getPhoneNumber = (id, setOpen1) => {
  return (dispatch, getState) => {
    api.getApiCall(
      endpoints.getPhone + `/${id}`,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        if (respData.data.status === "FAILURE") {
          //setOpen1(false)
          // dispatch(updateUser({ phoneDisplay: "", phoneMsgDisplay: respData.data.result.message }));
          dispatch(updateCommonData({
            message: respData.data.result.message,
            title: "Insufficient Credits",
            img: "/sub_exp.png",
            status: true,
            getPlanButton: true
          }));
        } else {
          setOpen1(true)
          dispatch(updateUser({ phoneDisplay: respData.data.result.phoneCountryCode + "-" + respData.data.result.phoneNumber }));
        }

      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};

export const getStateAndCity = () => {
  return (dispatch, getState) => {
    const { citySearch } = getState().userSlice;
    api.getApiCall(
      endpoints.getStateAndCity,
      `?search=${citySearch}`,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        let res = respData.data;

        if (res.status === FAILURE) {
          dispatch(updateCommonData({
            message: res.message || "Api error",
            title: "Login Failed",
            img: "/failed.png",
            status: true
          }));
        } else {
          dispatch(updateUser({ cityStateList: respData.data.result }));
        }
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};
export const getUserDetailsById = (id) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));
    api.getApiCall(
      endpoints.userDetails + id,
      ``,
      (respData) => {
        dispatch(updateGlobalLoader(false));
        dispatch(updateUser({ userDetails: respData.data.result }));
      },
      (errors) => {
        console.log("error", errors);
      }
    );
  };
};



export const otpVerify = (postData, navigate) => {
  return (dispatch, getState) => {
    dispatch(updateGlobalLoader(true));

    axios.post(BASE_URL + endpoints.otpVerification, postData, {
      headers: {
        "Authorization": sessionStorage.getItem("jwttoken") || ""
      },
    }).then((respData) => {
      dispatch(updateGlobalLoader(false));
      let res = respData.data;

      if (res.status === FAILURE) {
        dispatch(updateCommonData({
          message: res.message || "Api error",
          title: "Login Failed",
          img: "/failed.png",
          status: true
        }));
      } else {
        let response = res.result.userData;
        localStorage.setItem("user", JSON.stringify({ id: response?.id, roleId: response?.roleId, userType: response?.userType, }))
        localStorage.setItem("token", res.result.jwtToken)
        localStorage.setItem("type", response.userType)
        setCompanyFlowStatus(response.showCompanyFlow || 0);
        sessionStorage.removeItem("jwttoken");
        navigate("/");
      }
    }).catch(err => {
      dispatch(updateGlobalLoader(false));
      let { data } = err;
      Alert(2, data?.msg);
    })


  };
};